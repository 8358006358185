import React from "react"

import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import ResponsiveIFrame from "Components/ResponsiveIFrame"
import SEO from "Components/core/SEO"

const SteampunkSite: React.FC<GatsbyProps> = ({ location }) => (
  <StandardLayout location={location}>
    <SEO title="Steampunk Site" />
    <h1>Steampunk Site</h1>
    <p>
      My second university website was with a team of 4 with 3 creating the
      content for the site and myself developing the site. Much like my first
      site only CSS and HTML was used.
    </p>
    <p>
      Check it out in the iFrame or you can visit it on
      <a href="https://liammyles.github.io/past-static-projects/steampunk-arcade/">
        Github Pages Steampunk Site
      </a>
      \
    </p>
    <ResponsiveIFrame src="https://liammyles.github.io/past-static-projects/steampunk-arcade/" />
  </StandardLayout>
)

export default SteampunkSite
